<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right d-flex">
            <div class="me-4 d-flex">
              <label class="col-form-label me-2">Budget Year</label>
              <div style="width: 150px">
                <v-select label="name" v-model="year" :options="years" :clearable="false" :reduce="(name) => name.id"
                  @option:selected="getPlansByYear($event)">
                </v-select>
              </div>
            </div>
            <label class="col-form-label me-2">Month</label>
            <div style="width: 200px">
              <v-select label="name" v-model="month" :options="months" :clearable="false" :reduce="(name) => name.id"
                :selectable="(options) => isSelectableMonth(options)"
                @option:selected="getPlansByMonth">
              </v-select>
            </div>
            <div @click="refreshData" class="icon-css">
              <i style="color: #4a81d4" class="fe-rotate-cw"></i>
            </div>
          </div>
          <h4 class="page-title">User Enrollment</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <place-holder v-if="loading"></place-holder>

            <div class="table-responsive" v-if="!loading">
              <table class="table table-striped dt-responsive w-100 mb-3" id="monthly_plan_datatable" v-if="!loading">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Employee Type</th>
                    <th>Module</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <template v-for="(monthly_plan, index) in monthly_plans" :key="index">
                    <tr>
                      <td>{{ ++index }}</td>
                      <td>{{ monthly_plan.employee_type.name }}</td>
                      <td>{{ monthly_plan.module.name }}</td>
                      <td>{{ monthly_plan.from_date }}</td>
                      <td>{{ monthly_plan.to_date }}</td>
                      <td>
                        <span class="badge bg-info" v-if="monthly_plan.expired_date >= current_date"
                          data-bs-toggle="tooltip" title="Plan is during registration period!"
                          style="cursor: pointer">OnProgress
                        </span>
                        <span class="badge bg-warning" v-else data-bs-toggle="tooltip"
                          title="Plan is Over registration period!" style="cursor: pointer">Expired
                        </span>
                      </td>
                      <td>
                        <router-link :to="{
                          name: 'enroll-create',
                          params: { id: monthly_plan.id },
                        }">
                          <span>
                            <i class="fas fa-eye"></i>
                            View/Enrolls
                          </span>
                        </router-link>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </div>
</template>

<script>
//Datatable monthly_plans
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import moment from "moment";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      current_date: "",
      month: "",
      months: [],
      year: "",
      years: [],
      yearId: "",
      monthly_plans: [],
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    isSelectableMonth(options) {
      if(!this.month) return true;
      return this.month != options.id;
    },
    async getBudgetYear() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v1/yearly-training-plans`)
        .then((response) => {
          this.years = response.data.data;
          this.loading = false;
        });
    },

    async getPlansByYear(value) {
      this.yearId = value.id;
      this.monthly_plans = "";
      this.month = "";
      await this.getAllMonths(this.yearId);
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v1/show-plan-lists?budget_year_id=${this.yearId}`
        )
        .then((response) => {
          this.monthly_plans = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        });
      $("#monthly_plan_datatable").DataTable({
        destroy: true,
      });
    },
    // async getAllMonths() {
    //   axios.get(`${this.baseUrl}admin/v1/get-months`).then((response) => {
    //     let obj = response.data.data;
    //     this.months = Object.keys(obj).map((key) => {
    //       return { id: key, name: obj[key] };
    //     });
    //   });
    // },
    async getAllMonths(year_id) {
      axios
        .get(`${this.baseUrl}admin/v1/get-months/${year_id}`)
        .then((response) => {
          let obj = response.data.data;
          this.months = Object.keys(obj).map((key) => {
            return { id: key, name: obj[key] };
          });
        });
    },
    async getPlansByMonth() {
      if (this.yearId) {
        this.monthly_plans = "";
        this.loading = true;
        this.$Progress.start();
        await axios
          .get(
            `${this.baseUrl}admin/v1/show-plan-lists?budget_year_id=${this.yearId}&month=${this.month}`
          )
          .then((response) => {
            this.monthly_plans = response.data.data;
            this.loading = false;
            this.$Progress.finish();
          });
        $("#monthly_plan_datatable").DataTable({
          destroy: true,
        });
      } else {
        this.month = "";
        this.toast.error("Please Choose Budget Year First!");
      }
    },
    async getAllMonthlyPlans() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/show-plan-lists`)
        .then((response) => {
          this.monthly_plans = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        });
      $("#monthly_plan_datatable").DataTable({
        destroy: true,
      });
    },
    refreshData() {
      this.year = "";
      this.yearId = "";
      this.month = "";
      this.monthly_plans = "";
      this.getBudgetYear();
      // this.getAllMonths();
      this.getAllMonthlyPlans();
      this.current_date = moment().format("YYYY-MM-DD");
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#monthly_plan_datatable").DataTable().draw();
    },
  },
  created() {
    this.clearFilter();
    this.getBudgetYear();
    // this.getAllMonths();
    this.getAllMonthlyPlans();
    this.current_date = moment().format("YYYY-MM-DD");
  },
  components: {
    vSelect,
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}

a {
  color: #4a81d4;
  text-decoration: none;
}

</style>
